define("discourse/plugins/chat/discourse/components/chat/direct-message-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax-error", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _ajaxError, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatDirectMessageButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get shouldRender() {
      return this.chat.userCanDirectMessage && !this.args.user.suspended;
    }
    async startChatting() {
      try {
        const channel1 = await this.chat.upsertDmChannel({
          usernames: [this.args.user.username]
        });
        if (channel1) {
          this.router.transitionTo("chat.channel", ...channel1.routeModels);
        }
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      } finally {
        if (this.args.modal) {
          this.appEvents.trigger("card:close");
        }
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "startChatting", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldRender}}
          <DButton
            @action={{this.startChatting}}
            @label="chat.title_capitalized"
            @icon="d-chat"
            class="btn-primary chat-direct-message-btn"
          />
        {{/if}}
      
    */
    {
      "id": "6RpBaCSc",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"btn-primary chat-direct-message-btn\"]],[[\"@action\",\"@label\",\"@icon\"],[[30,0,[\"startChatting\"]],\"chat.title_capitalized\",\"d-chat\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/home/pacs/sib00/users/doerfli_bs/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/direct-message-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatDirectMessageButton;
});