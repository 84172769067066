define("discourse/plugins/chat/discourse/components/chat-composer-upload", ["exports", "@glimmer/component", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse/lib/uploads", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dButton, _concatClass, _uploads, _dIcon, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatComposerUpload extends _component.default {
    get isImage() {
      return (0, _uploads.isImage)(this.args.upload.original_filename || this.args.upload.fileName);
    }
    get fileName() {
      return this.args.isDone ? this.args.upload.original_filename : this.args.upload.fileName;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @upload}}
          <div
            class={{concatClass
              "chat-composer-upload"
              (if this.isImage "chat-composer-upload--image")
              (unless @isDone "chat-composer-upload--in-progress")
            }}
          >
            <div class="preview">
              {{#if this.isImage}}
                {{#if @isDone}}
                  <img class="preview-img" src={{@upload.short_path}} />
                {{else}}
                  {{dIcon "far-image"}}
                {{/if}}
              {{else}}
                {{dIcon "file-alt"}}
              {{/if}}
            </div>
    
            <span class="data">
              {{#unless this.isImage}}
                <div class="top-data">
                  <span class="file-name">{{this.fileName}}</span>
                </div>
              {{/unless}}
    
              <div class="bottom-data">
                {{#if @isDone}}
                  {{#unless this.isImage}}
                    <span class="extension-pill">{{@upload.extension}}</span>
                  {{/unless}}
                {{else}}
                  {{#if @upload.processing}}
                    <span class="processing">{{i18n "processing"}}</span>
                  {{else}}
                    <span class="uploading">{{i18n "uploading"}}</span>
                  {{/if}}
    
                  <progress
                    class="upload-progress"
                    id="file"
                    max="100"
                    value={{@upload.progress}}
                  ></progress>
                {{/if}}
              </div>
            </span>
    
            <DButton
              @action={{@onCancel}}
              @icon="times"
              @title="chat.remove_upload"
              class="btn-flat chat-composer-upload__remove-btn"
            />
          </div>
        {{/if}}
      
    */
    {
      "id": "R4SqodTE",
      "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[10,0],[15,0,[28,[32,0],[\"chat-composer-upload\",[52,[30,0,[\"isImage\"]],\"chat-composer-upload--image\"],[52,[51,[30,2]],\"chat-composer-upload--in-progress\"]],null]],[12],[1,\"\\n        \"],[10,0],[14,0,\"preview\"],[12],[1,\"\\n\"],[41,[30,0,[\"isImage\"]],[[[41,[30,2],[[[1,\"              \"],[10,\"img\"],[14,0,\"preview-img\"],[15,\"src\",[30,1,[\"short_path\"]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[28,[32,1],[\"far-image\"],null]],[1,\"\\n\"]],[]]]],[]],[[[1,\"            \"],[1,[28,[32,1],[\"file-alt\"],null]],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n\\n        \"],[10,1],[14,0,\"data\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"isImage\"]]],[[[1,\"            \"],[10,0],[14,0,\"top-data\"],[12],[1,\"\\n              \"],[10,1],[14,0,\"file-name\"],[12],[1,[30,0,[\"fileName\"]]],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n          \"],[10,0],[14,0,\"bottom-data\"],[12],[1,\"\\n\"],[41,[30,2],[[[41,[51,[30,0,[\"isImage\"]]],[[[1,\"                \"],[10,1],[14,0,\"extension-pill\"],[12],[1,[30,1,[\"extension\"]]],[13],[1,\"\\n\"]],[]],null]],[]],[[[41,[30,1,[\"processing\"]],[[[1,\"                \"],[10,1],[14,0,\"processing\"],[12],[1,[28,[32,2],[\"processing\"],null]],[13],[1,\"\\n\"]],[]],[[[1,\"                \"],[10,1],[14,0,\"uploading\"],[12],[1,[28,[32,2],[\"uploading\"],null]],[13],[1,\"\\n\"]],[]]],[1,\"\\n              \"],[10,\"progress\"],[14,0,\"upload-progress\"],[14,1,\"file\"],[14,\"max\",\"100\"],[15,2,[30,1,[\"progress\"]]],[12],[13],[1,\"\\n\"]],[]]],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[32,3],[[24,0,\"btn-flat chat-composer-upload__remove-btn\"]],[[\"@action\",\"@icon\",\"@title\"],[[30,3],\"times\",\"chat.remove_upload\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@upload\",\"@isDone\",\"@onCancel\"],false,[\"if\",\"unless\"]]",
      "moduleName": "/home/pacs/sib00/users/doerfli_bs/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-composer-upload.js",
      "scope": () => [_concatClass.default, _dIcon.default, _i18n.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatComposerUpload;
});