define("discourse/plugins/chat/discourse/components/chat/message-creator/channel", ["exports", "@glimmer/component", "@ember/service", "truth-helpers", "discourse/helpers/concat-class", "discourse/plugins/chat/discourse/components/channel-title", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _truthHelpers, _concatClass, _channelTitle, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Channel extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get isUrgent() {
      return this.args.item.model.isDirectMessageChannel || this.args.item.model.isCategoryChannel && this.args.item.model.tracking.mentionCount > 0;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="chat-message-creator__chatable -category-channel"
          data-disabled={{not @item.enabled}}
        >
          <ChannelTitle @channel={{@item.model}} />
    
          {{#if (gt @item.tracking.unreadCount 0)}}
    
            <div
              class={{concatClass "unread-indicator" (if this.isUrgent "-urgent")}}
            ></div>
          {{/if}}
        </div>
      
    */
    {
      "id": "aRGreTuk",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-creator__chatable -category-channel\"],[15,\"data-disabled\",[28,[32,0],[[30,1,[\"enabled\"]]],null]],[12],[1,\"\\n      \"],[8,[32,1],null,[[\"@channel\"],[[30,1,[\"model\"]]]],null],[1,\"\\n\\n\"],[41,[28,[32,2],[[30,1,[\"tracking\",\"unreadCount\"]],0],null],[[[1,\"\\n        \"],[10,0],[15,0,[28,[32,3],[\"unread-indicator\",[52,[30,0,[\"isUrgent\"]],\"-urgent\"]],null]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@item\"],false,[\"if\"]]",
      "moduleName": "/home/pacs/sib00/users/doerfli_bs/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/channel.js",
      "scope": () => [_truthHelpers.not, _channelTitle.default, _truthHelpers.gt, _concatClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Channel;
});