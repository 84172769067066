define("discourse/plugins/chat/discourse/components/chat/routes/channels", ["exports", "@glimmer/component", "@ember/service", "discourse-common/helpers/i18n", "discourse/plugins/chat/discourse/components/channels-list-public", "discourse/plugins/chat/discourse/components/chat/navbar", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _i18n, _channelsListPublic, _navbar, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatRoutesChannels extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="c-routes --channels">
          <Navbar as |navbar|>
            <navbar.Title @title={{i18n "chat.chat_channels"}} />
            <navbar.Actions as |action|>
              <action.BrowseChannelsButton />
            </navbar.Actions>
          </Navbar>
    
          <ChannelsListPublic />
        </div>
      
    */
    {
      "id": "RtT0+F4k",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-routes --channels\"],[12],[1,\"\\n      \"],[8,[32,0],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"Title\"]],null,[[\"@title\"],[[28,[32,1],[\"chat.chat_channels\"],null]]],null],[1,\"\\n        \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,2,[\"BrowseChannelsButton\"]],null,null,null],[1,\"\\n        \"]],[2]]]]],[1,\"\\n      \"]],[1]]]]],[1,\"\\n\\n      \"],[8,[32,2],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"navbar\",\"action\"],false,[]]",
      "moduleName": "/home/pacs/sib00/users/doerfli_bs/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/routes/channels.js",
      "scope": () => [_navbar.default, _i18n.default, _channelsListPublic.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatRoutesChannels;
});